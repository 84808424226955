import { useAlertMessage, useTextLocality } from "@/shared/hooks";
import { useRef, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useAnswerToFeedback, useDeleteFeedback, useGetFeedbackList, useSendMoney } from "@/features/feedback/api";
import clsx from "clsx";
import styles from "@/features/chat/ui/chat-form/ChatForm.module.scss";
import { backgroundChat, EmojiIconJSX, PaperPlaneJSX } from "@/shared/assets/chat/svg";
import { Button, Checkbox, FormWrapper } from "@/shared/ui/form";
import { TextArea } from "@/shared/ui/form/textarea";
import EmojiPicker from "emoji-picker-react";
import useOutsideClick from "@/shared/hooks/useOutsideClick";
import { Message } from "@/features/feedback/ui/feedback-form/message";
import { IFeedback, IFeedbackSendMoney } from "@/shared/services/types/feedback.types";

export const FeedbackForm = () => {
  const {getMessage} = useTextLocality()
  const [activeId, setActiveId] = useState<number | undefined>(undefined)
  const [userId, setUserId] = useState<number | undefined>(undefined)
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined)
  const {isActive, ref: refEmoji} = useOutsideClick(true)
  const refButton = useRef<HTMLButtonElement>(null)
  const [showEmoji, setShowEmoji] = useState(false)
  const {sendAlertMessage} = useAlertMessage('error')
  const [isSendReward, setIsSendReward] = useState(false)
  const [lang, setLang] = useState<'EN' | 'CN'>('CN')

  const {mutateAsync: sendAnswer} = useAnswerToFeedback()

  const {mutateAsync: sendMoney} = useSendMoney()

  const {data} = useGetFeedbackList()

  const {mutateAsync: deleteFeedback} = useDeleteFeedback()

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      message: '',
      file: null
    }
  })

  const onSubmit = (data: FieldValues) => {
    if (activeId) {
      const res = {
        message: data.message,
        lang,
        feedback_id: activeId
      }

      sendAnswer(res).then(r => form.reset({message: '', file: null}))

      if (userId && isSendReward) {
        const res: IFeedbackSendMoney = {
          money: 3000,
          user_id: userId,
          email: userId ? undefined : userEmail
        }

        sendMoney(res)
      }
    }
  }

  const onKeyDown = () => {
    if (refButton.current) {
      refButton.current.click()
    }
  }

  const onClickMessage = (item: IFeedback) => {
    setUserId(undefined)
    setUserEmail(undefined)

    setActiveId(item.id)

    if (!item.name.split('|')[ 1 ]) {
      return
    }

    const userId = item.name.split('|')[ 1 ].trim()

    if (!isNaN(Number(userId))) {
      setUserId(Number(userId))
      setUserEmail(item.email)
    }
  }

  const changeLang = (lang: 'EN' | 'CN') => {
    setLang(lang)
  }

  const changeIsSendReward = () => {
    setIsSendReward(!isSendReward)
  }

  const onDeleteMessage = (id: number) => {
    try {
      deleteFeedback(id)
    } catch (e) {
      sendAlertMessage('Delete message error')
    }
  }

  return (
    <>
      <div
        className={clsx(styles.container, styles.containerBackground)}
        style={{backgroundImage: backgroundChat}}
      >
        {data?.data.map(el => (
          <Message item={el} isActive={activeId === el.id} key={el.id} onClick={onClickMessage}
                   onDelete={onDeleteMessage}/>
        ))}
      </div>

      <div className={styles.controlPanel}>
        <div className={styles.buttons}>
          <Button
            className={clsx(
              lang === 'EN' && styles.buttonActive
            )}
            modifiers={['inverse']}
            onClick={() => changeLang('EN')}
          >
            {getMessage('english')}
          </Button>
          <Button
            className={clsx(
              lang === 'CN' && styles.buttonActive
            )}
            modifiers={['inverse']}
            onClick={() => changeLang('CN')}
          >
            {getMessage('chinese')}
          </Button>
        </div>
        {userId && <div className={styles.status}>
          <Checkbox
            name='status'
            checked={isSendReward}
            title={getMessage('send_money')}
            modifier={['card']}
            onChange={changeIsSendReward}
          />
        </div>}
      </div>
      <FormWrapper
        className={clsx(styles.form, styles.container)}
        methods={form}
        onSubmit={onSubmit}
      >
        <TextArea
          containerClassName={styles.inputContainer}
          className={styles.input}
          placeholder={getMessage('sendMessage')}
          name='message'
          disabled={!activeId}
          connect
          maxRow={2}
          onKeyDownHandler={onKeyDown}
          registerConfig={{required: true}}
        />
        <div className={styles.wrapperEmoji} ref={refEmoji}>
          <button
            type='button'
            disabled={!activeId}
            onClick={() => setShowEmoji(prev => !prev)}
          >
            <EmojiIconJSX/>
          </button>
          {showEmoji && (
            <div className={styles.emoji}>
              <EmojiPicker
                skinTonesDisabled
                height={400}
                previewConfig={{showPreview: false}}
                onEmojiClick={({emoji}) => {
                  form.setValue('message', form.watch('message') + emoji)
                }}
              />
            </div>
          )}
        </div>
        <button type='submit' ref={refButton}>
          <PaperPlaneJSX/>
        </button>
      </FormWrapper>
    </>

  )
}
