import { ILinkItemMenuProps } from '@/shared/constants.interface'
import clsx from 'clsx'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import styles from './NavMenu.module.scss'
import { useGetFeedbackList } from "@/features/feedback/api";

interface IProps {
	dataTabMenu: Array<ILinkItemMenuProps>
}

export const NavMenu: FC<IProps> = ({ dataTabMenu }) => {
	const { pathname } = useLocation()
	const {data} = useGetFeedbackList()

	return (
		<>
			{dataTabMenu.map((item_menu, index) => {
				if (item_menu.itemMenu.subMenu) {
					return (
						<div className='element' key={index}>
							<div className='navBlock navBlock_info'>
								{/* <Link to={`${item_menu.link}`} className='navBlock_info'> */}
								<FormattedMessage id={item_menu.itemMenu.itemName} />
								{/* </Link> */}
								{!!item_menu.itemMenu.price && (
									<span style={{ fontWeight: 'bold' }}>
										{item_menu.itemMenu.price} $
									</span>
								)}
							</div>
							<div id={item_menu.link} className='subMenu'>
								{item_menu.itemMenu.subMenu.map((item_sub_menu, index) => {
									const isActive = pathname.includes(
										item_sub_menu.itemMenu.itemName
									)

									return (
										<Link
											key={index}
											className={clsx(isActive && 'active')}
											to={item_sub_menu.link}
										>
											<span>
												{item_sub_menu.itemMenu.image &&
													item_sub_menu.itemMenu.image}
											</span>
											<FormattedMessage id={item_sub_menu.itemMenu.itemName} />
											{item_sub_menu.itemMenu.itemName === 'feedback'
												? data?.data.find(el => el.status !== 'Done') && (
													<div className={styles.point} />
												) :  item_sub_menu.itemMenu.isPoint && (
												<div className={styles.point} />
											)}
										</Link>
									)
								})}
							</div>
						</div>
					)
				} else
					return (
						<Link key={index} to={item_menu.link}>
							<FormattedMessage id={item_menu.itemMenu.itemName} />
						</Link>
					)
			})}
		</>
	)
}
