import { useTextLocality } from "@/shared/hooks";
import { Layout } from "@/shared/ui/layout";
import { FeedbackForm } from "@/features/feedback/ui/feedback-form";

const FeedbackPage = () => {
  const {getMessage} = useTextLocality()

  return (
    <Layout
      position='left'
      title={getMessage('dashboard')}
      description={getMessage('feedback')}
    >
      <FeedbackForm/>
    </Layout>
  )
}

export default FeedbackPage