import { useMutation } from "@tanstack/react-query";
import FeedbackService from "@/shared/services/feedback.service";
import { queryClient } from "@/shared/lib";

export const useDeleteFeedback = () => {
  return useMutation(['delete feedback'], (id: number) => FeedbackService.deleteFeedback(id), {
    onSuccess: () => {
     queryClient.invalidateQueries(['get feedback list'])
    }
  })
}