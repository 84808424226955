import { LOCALES } from './locales'

export const messages = {
	[LOCALES.RUSSIA]: {
		//pages
		dashboard: 'Приборная панель',
		statistics: 'Статистика',
		payment_statistic: 'Статистика платежей',
		payment_statistic_user: 'Платежи пользователя',
		marketing_statistic: 'Маркетинговая статистика',
		general_statistic: 'Общая статистика',
		user_statistic: 'Статистика пользователя',
		new_registrations: 'Новые регистрации',
		games: 'Игры',
		users: 'Пользователи',
		general: 'Общее',
		marketing: 'Маркетинг',
		payments: 'Платежи',
		match: 'Матчи',
		sport: 'Спорт',
		market: 'Маркет',
		rates: 'Тарифы',
		deposits: 'Депозиты',
		content: 'Контент',
		experts: 'Эксперты',
		school: 'Школа',
		analytics: 'Аналитика',
		dictionary: 'Словарь',
		shop: 'Магазин',
		blog: 'Блог',
		chat: 'Чат',
		feedback: 'Обратная связь',

		advertising: 'Реклама',
		to_payments: 'К платежам',
		search: 'Поиск',
		save: 'Сохранить',
		'new-category': 'Добавить категорию',
		'edit-category': 'Редактировать категорию',
		'new-article': 'Добавить статью',
		'edit-article': 'Редактировать статью',
		'add-product': 'Добавить товар',
		'edit-product': 'Редактировать товар',
		welcomeBack: 'С возвращением',
		ru: 'Ру',
		en: 'En',
		cn: 'Cn',
		english: 'Английский',
		chinese: 'Китайский',
		systemAdmin: 'Системный администратор',
		manager: 'Менеджер',
		user: 'Пользователь',
		unknown: 'Unknown',

		active: 'Активный',
		very_active: 'Очень активный',
		inactive: 'Неактивный',
		win: 'Победа',
		loss: 'Проигрыш',
		not_calculated: 'Не рассчитана',
		in_progress: 'В процессе',
		received: 'Получено',

		category: 'Категории',
		article: 'Статьи',
		forgot_pass: 'Забыли пароль?',
		login: 'Войти',
		recover: 'Восстановить',
		close: 'Закрыть',
		show: 'Show',
		hide: 'Hide',
		okay: 'Ок',
		logout: 'Выйти',
		edit: 'Редактировать',
		delete: 'Удалить',
		redirect: 'Перенаправление',
		view: 'Посмотреть',
		clear: 'Очистить',
		close_appeals: 'Закрыть апелляции',
		not_found: 'Ничего не найдено',
		show_articles: 'Показать статьи',
		show_analytic: 'Показать аналитику',
		send_money: 'Отправить деньги',

		confirm_ask: 'Вы уверены, что хотите удалить?',
		confirm_ask_publish: 'Вы уверены, что хотите опубликовать?',
		confirm_yes: 'Да, хочу',
		confirm_no: 'Нет, не хочу',

		new_category: 'Новая категория',
		new_analytic: 'Новая аналитика',
		new_article: 'Новая статья',
		new_product: 'Новый продукт',
		new_expert: 'Новый эксперт',

		edit_category: 'Редактировать категорию',
		edit_analytic: 'Редактировать аналитику',
		edit_article: 'Редактировать статью',
		edit_product: 'Редактировать продукт',
		edit_expert: 'Редактировать эксперта',

		//form
		login_pass: 'Пароль',
		login_email: 'Email',
		publish: 'Опубликовать',
		publish_all: 'Опубликовать все',
		required_field_tool: 'Поля обязательные к заполнению',
		rating_read_text: 'Рейтинг за прочтение',
		count_rating_text: 'Минимальное количество рейтинга для просмотра',
		level: 'Уровень',
		rating_read_buy: 'Рейтинг за покупку',
		rating: 'Рейтинг',
		wallet: 'Кошелек',
		rating_view_video: 'Рейтинг за просмотр видео',
		publish_now: 'Опубликовать сразу?',
		is_active: 'Активна?',
		is_hidden: 'Пост не доступен без регистрации?',
		promocode_list: 'Промокоды через пробел',
		promocode_count: 'Количество промокодов',

		title: 'Заголовок',
		description: 'Описание',
		description_short: 'Краткое описание',
		image: 'Изображение',
		price: 'Цена',
		video: 'Видео',
		//Errors form
		required_field: 'Обязательное поле',
		correct_email: 'Введите корректный адрес электронной почты',
		correct_password: 'Введите корректный пароль',
		correct_password_repeat: `Пароли не совпадают`,
		correct_length: `Введите корректную длину`,
		minimum_characters: 'Минимум символов',

		//users
		betting_history: 'История ставок',
		limitations: 'Ограничения',
		transaction_history: 'История транзакций',
		game_history: 'История игры',
		bonuses: 'Бонусы',

		betting_amount: 'Сумма ставки',
		coefficient: 'Коэффициент',
		no_limited: 'Без лимита',

		name: 'Имя',
		user_id: 'Имя ID',
		email: 'Email',
		geography: 'География',
		balance: 'Баланс',
		experience: 'Опыт',
		date_registration: 'Дата регистрации',
		activity_time: 'Активное время',

		searchById: 'Поиск по ID',
		searchByEvent: 'Поиск по событию',
		searchByNameContact: 'Поиск по имени, контакту, региону, статусу',
		statisticsWinAndLoss: 'Статистика побед и поражений',
		number_users: 'Количество пользователей',
		totalRegistrations: 'Общее количество регистраций',
		activeUserWeek: 'Активные пользователи за неделю',
		newUserWeek: 'Новые пользователи за неделю',
		amountDeposits: 'Сумма депозитов',
		totalValueBet: 'Общий объем ставок',
		totalUsers: 'Все пользователи',
		prevMonth: 'предыдущий месяц',
		prevWeek: 'предыдущая неделя',
		prevDay: 'предыдущий день',
		prevYears: 'предыдущий год',
		monthly: 'Ежемесячно',
		weekly: 'Еженедельно',
		daily: 'Ежедневно',
		annually: 'Ежегодно',
		winning: 'Победа',
		numberReferrals: 'Количество обращений',
		not_received: 'Не получал',

		filters: `Фильтры`,
		filter: `Фильтр`,
		clear_filter: 'Очистить фильтр',
		sort: 'Сортировка',
		sortAZ: 'Сортировать от A до Z',
		sortZA: 'Сортировать от Z до A',

		activity: `Активность`,

		by_date: 'По дате',
		from_date: 'От',
		to_date: 'До',

		//table

		id: 'ID',
		date: 'Дата/время',
		amount: 'Сумма',
		event: 'Мероприятие',
		sport_type: 'Спортивный тип',
		bet: 'Ставка',
		status: 'Статус',
		deposits_purchases: 'Депозиты/закупки',
		purchase: 'Закупки',
		game: 'Игра',
		contact: 'Контакт',
		region: 'Регион',
		type: 'Тип',
		timestamp: 'Временная метка',
		trafficSource: 'Источник трафика',
		numberVisitor: `Количество посетителей`,
		numberRegistration: `Номер регистрации`,
		conversion: 'Конверсия',
		retention_rate: `Коэффициент удержания`,
		referrals: `Рефераты`,
		result: 'Результат',

		//chat
		sendMessage: 'Отправить сообщение',
		newAppeals: 'Новые обращения',
		workspaceAppeals: 'Апелляции по рабочим местам',
		history: 'История',

		//Error Backend
		'Новых обращений нет': 'Новых обращений нет',
		'Not Found Bets': 'Ставки не найдены',
		'Not Found': `Не найдено`
	},
	[LOCALES.ENGLISH]: {
		dashboard: 'Dashboard',
		payment_statistic: 'Payment statistics',
		payment_statistic_user: 'Payment user',
		marketing_statistic: 'Marketing statistics',
		general_statistic: 'General statistics',
		user_statistic: 'User Statistics',
		statistics: 'Statistics',
		new_registrations: 'New registrations',
		games: 'Games',
		users: 'Users',
		match: 'Match',
		general: 'General',
		marketing: 'Marketing',
		payments: 'Payments',
		sport: 'Sport',
		market: 'Market',
		rates: 'Rates',
		deposits: 'Deposits',
		content: 'Content',
		experts: 'Experts',
		school: 'School',
		analytics: 'Analytics',
		dictionary: 'Dictionary',
		shop: 'Shop',
		chat: 'Chat',
		feedback: 'Feedback',
		send_money: 'Send money',
		blog: 'Blog',
		advertising: 'Advertising',
		to_payments: 'To payments',
		search: 'Search',
		save: 'Save',
		'new-category': 'Add category',
		'edit-category': 'Edit category',
		'new-article': 'New article',
		'edit-article': 'Edit article',
		'add-product': 'Add product',
		'edit-product': 'Edit product',
		welcomeBack: 'Welcome back',
		user_statistics: 'User statistics',
		ru: 'Ru',
		en: 'En',
		cn: 'Cn',
		english: 'English',
		chinese: 'Chinese',
		systemAdmin: 'System administrator',
		manager: 'Manager',
		user: 'User',
		unknown: 'Unknown',

		active: 'Active',
		very_active: 'Very active',
		inactive: 'Inactive',
		win: 'Win',
		loss: 'Loss',
		not_calculated: 'Not calculated',
		in_progress: 'In progress',
		received: 'Received',
		category: 'Category',
		article: 'Article',
		forgot_pass: 'Forgot password?',
		login: 'Login',
		recover: 'Recover',
		close: 'Close',
		show: 'Show',
		hide: 'Hide',
		okay: 'Ok',
		logout: 'Logout',

		edit: 'Edit',
		delete: 'Delete',
		redirect: 'Redirect',
		view: 'View',
		clear: 'Clear',
		close_appeals: 'Close the appeals',
		show_articles: 'Show articles',
		show_analytic: 'Show analytics',

		is_active: 'is Active?',
		is_hidden: 'The post is not available without registration?',
		not_found: 'Not found',

		confirm_ask: 'Are you sure you want to delete?',
		confirm_ask_publish: 'Are you sure you want to publish?',
		confirm_yes: 'Yes, I do',
		confirm_no: `No, I don't`,

		new_category: 'New category',
		new_analytic: 'New analytic',
		new_article: 'New article',
		new_product: 'New product',
		new_expert: 'New expert',

		edit_category: 'Edit category',
		edit_analytic: 'Edit analytic',
		edit_article: 'Edit article',
		edit_product: 'Edit product',
		edit_expert: 'Edit expert',

		//form
		login_pass: 'Password',
		login_email: 'Email',
		publish: 'To publish',
		publish_all: 'To all publish',

		required_field_tool: 'Required fields',
		rating_read_text: 'Rating for reading',
		count_rating_text: 'Minimum number of ratings to view',
		level: 'Level',
		rating_read_buy: 'Rating for purchase',
		rating: 'Rating',
		wallet: 'Wallet',
		rating_view_video: 'Rating for video views',
		publish_now: 'Publish it right away?',
		promocode_list: 'Promo codes through the space bar',
		promocode_count: 'Number of promo codes',
		price: 'Price',
		title: 'Title',
		description: 'Description',
		description_short: 'Short description',
		image: 'Image',
		video: 'Video',

		//Errors form
		required_field: 'Required field',
		correct_email: 'Enter a valid email address',
		correct_password: 'Enter a valid password',
		correct_password_repeat: `Passwords don't match`,
		correct_length: `Enter the correct length`,
		minimum_characters: 'The minimum number of characters',

		//users
		betting_history: 'Betting History',

		limitations: 'Limitations',
		transaction_history: 'Transaction History',
		game_history: 'Game History',
		bonuses: 'Bonuses',

		betting_amount: 'Betting amount',
		coefficient: 'Coefficient',
		no_limited: 'No limited',

		name: 'Name',
		user_id: 'Users ID',
		email: 'Email',
		geography: 'Geography',
		balance: 'Balance',
		experience: 'Experience',
		date_registration: 'Date Registration',
		activity_time: 'Activity time',

		searchById: 'Search by ID',
		searchByEvent: 'Search by event',
		searchByNameContact: 'Search by name, contact, region, status',
		statisticsWinAndLoss: 'Statistics of wins and losses',
		number_users: 'Number of users',
		totalRegistrations: 'Total number of registrations',
		activeUserWeek: 'Active users for the week',
		newUserWeek: 'New users in a week',
		totalUsers: 'Total users',
		amountDeposits: 'Amount of deposits',
		totalValueBet: 'Total volume of bets',
		prevMonth: 'previous month',
		prevWeek: 'previous week',
		prevDay: 'previous day',
		prevYears: 'previous year',
		monthly: 'Monthly',
		weekly: 'Weekly',
		daily: 'Daily',
		annually: 'Annually',
		winning: 'Winning',
		numberReferrals: 'Number of referrals',
		not_received: 'Did not receive',

		filters: `Filters`,
		filter: `Filter`,
		clear_filter: 'Clear filter',
		sort: 'Sort',
		sortAZ: 'Sort from A to Z',
		sortZA: 'Sort from Z to A',
		activity: `Activity`,

		by_date: 'By date',
		from_date: 'From date',
		to_date: 'To date',

		//table

		id: 'ID',
		date: 'Date / Time',
		amount: 'The amount',
		event: 'Event',
		sport_type: 'Sport type',
		bet: 'Bet',
		status: 'Status',
		deposits_purchases: 'Deposits/Purchases',
		purchase: 'Purchases',
		game: 'Game',
		type: 'Type',
		contact: 'Contact',
		region: 'Region',
		timestamp: 'Timestamp',
		trafficSource: 'Traffic source',
		numberVisitor: `Number of Visitors`,
		numberRegistration: `Number of Registrations`,
		conversion: 'Conversion',
		retention_rate: `Retention Rate`,
		referrals: `Referrals`,
		result: 'Result',

		//chat

		sendMessage: 'Send message',
		newAppeals: 'New appeals',
		workspaceAppeals: 'Workspace appeals',
		history: 'History',

		//Error Backend
		'Новых обращений нет': 'No new applications',
		'Not Found Bets': 'Not Found Bets',
		'Not Found': `Not Found`
	},
	[LOCALES.CHINESE]: {
		//pages
		dashboard: '仪表板',
		payment_statistic: '付款统计',
		payment_statistic_user: '付款用户',
		marketing_statistic: '营销统计',
		general_statistic: '一般统计数据',
		user_statistic: '用户统计я',
		statistics: '统计资料',
		new_registrations: '新注册',
		games: '游戏',
		users: '用户',
		general: '一般',
		marketing: '市场营销',
		payments: '付款',
		match: '比赛',
		sport: '体育',
		market: '市场',
		rates: '费率',
		deposits: '存款',
		content: '内容',
		experts: '專家',
		school: '学校',
		analytics: '分析',
		dictionary: '词典',
		shop: '购物',
		blog: '博客',
		chat: '聊天',
		feedback: '反馈意见',
		send_money: '汇款',
		advertising: '广告',
		to_payments: '付款',
		search: '搜索',
		save: '节省',
		'new-category': '添加类别',
		'new-expert': '新專家',
		'edit-category': '编辑类别',
		'new-article': '新文章',
		'edit-article': '编辑文章',
		'add-product': '添加项目',
		'edit-product': '编辑产品',
		welcomeBack: '欢迎回来',
		user_statistics: '用户统计',
		ru: 'Ru',
		en: 'En',
		cn: 'Cn',
		english: '英语',
		chinese: '中文',
		systemAdmin: '系统管理员',
		manager: '经理',
		user: '用户',
		unknown: 'Unknown',

		active: '活动',
		very_active: '非常活跃',
		inactive: '消极',
		win: '赢',
		loss: '损失',
		not_calculated: '未计算',
		in_progress: '进行中',
		received: '收到',

		category: '类别',
		article: '文章',
		forgot_pass: '忘记密码？',
		login: '登录',
		recover: '恢复',
		close: '关闭',
		show: '显示',
		hide: '隐藏',
		show_articles: '显示文章',
		show_analytic: '顯示分析',

		okay: '好的',
		logout: '注销',
		edit: '编辑',
		delete: '删除',
		redirect: '重定向',
		clear: '清晰',
		view: '查看',
		close_appeals: '结束上诉',
		not_found: '未找到',

		confirm_yes: '是的，我愿意',
		confirm_no: `不，我没有`,
		confirm_ask: '您确定要删除吗？',
		confirm_ask_publish: '你确定要出版吗？',

		new_category: '新类别',
		new_analytic: '新的分析方法',
		new_article: '新文章',
		new_product: '新产品',
		new_expert: '新專家',

		edit_category: '编辑类别',
		edit_analytic: '编辑分析',
		edit_article: '编辑文章',
		edit_product: '编辑产品',
		edit_expert: '編輯專家',

		//form
		login_pass: '密码',
		login_email: '电子邮件',

		publish: '出版',
		publish_all: '致所有出版商',
		is_active: '活跃?',
		is_hidden: '没有注册就不能获得该职位？',

		required_field_tool: '需要填写的字段',
		rating_read_text: '阅读评分',
		count_rating_text: '可檢視的最低評分數',
		level: '等級',
		wallet: '钱包',
		rating: '评级',
		rating_read_buy: '购买评级',
		rating_view_video: '视频浏览量评级',
		publish_now: '立即出版？',
		promocode_list: '通过空格键输入促销代码',
		promocode_count: '促销代码数量',
		price: '价格',
		title: '标题',
		description: '说明',
		description_short: '简要说明',
		image: '图片',
		video: '视频',

		//Errors form
		required_field: '必须填写',
		correct_email: '输入有效的电子邮件地址',
		correct_password: '输入有效密码',
		correct_password_repeat: `密码不匹配`,
		correct_length: `输入正确的长度`,
		minimum_characters: '最少字符数',

		//users
		betting_history: '投注历史',
		limitations: '局限性',
		transaction_history: '交易历史',
		game_history: '游戏历史',
		bonuses: '奖金',

		betting_amount: '投注额',
		coefficient: '系数',
		no_limited: '无限制',

		name: '名称',
		user_id: '用户 ID',
		email: '电子邮件',
		geography: '地理',
		balance: '平衡',
		experience: '经验',
		date_registration: '注册日期',
		activity_time: '活动时间',

		searchById: '按 ID 搜索',
		searchByEvent: '按活动搜索',
		searchByNameContact: '按姓名、联系人、地区、状态搜索',
		statisticsWinAndLoss: '胜负统计',
		number_users: '用户数量',
		totalRegistrations: '注册总数',
		activeUserWeek: '本周活跃用户',
		newUserWeek: '一周内新增用户',
		totalUsers: '用户总数',
		amountDeposits: '存款额',
		totalValueBet: '总投注额',
		prevMonth: '上个月',
		prevWeek: '上星期',
		prevDay: '前一天',
		prevYears: '前一年',
		monthly: '每月',
		weekly: '每周',
		daily: '每日',
		annually: '每年',
		winning: '获胜',
		numberReferrals: '转介数量',
		not_received: '未收到',

		filters: `过滤器`,
		filter: `过滤器`,
		clear_filter: '清洁过滤器',
		sort: '分类',
		sortAZ: '从 A 到 Z 排序',
		sortZA: '从 Z 到 A 排序',
		activity: `活动`,

		by_date: '按日期',
		from_date: '从日期',
		to_date: '迄今为止',

		//table

		id: '身份证',
		date: '日期/时间',
		amount: '金额',
		event: '活动',
		sport_type: '运动型',
		bet: '投注',
		status: '现状',
		deposits_purchases: '存款/购买',
		purchase: '购买',
		game: '游戏',
		type: '类型',
		contact: '联系方式',
		region: '地区',
		timestamp: '时间戳',
		trafficSource: '流量来源',
		numberVisitor: `参观人数`,
		numberRegistration: `注册人数s`,
		conversion: '改装',
		retention_rate: `保留率`,
		referrals: `转介`,
		result: '结果',

		//chat

		sendMessage: '发送信息',
		newAppeals: '新申诉',
		workspaceAppeals: '工作空间申诉',
		history: '历史',

		//Error Backend
		'Новых обращений нет': '无新申请',
		'Not Found Bets': '未找到的投注',
		'Not Found': `未找到`
	}
}
