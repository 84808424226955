import { createBrowserRouter } from 'react-router-dom'

import { SuspenseWrapper } from '@/shared/ui/suspense'
import '@/Assets/sass/base.scss'
import Dashboard from '@/pages/main'
import NotFound from '@/pages/nof-found'
import Schools from '@/pages/school'
import SchoolEdit from '@/pages/school/[id]/edit-category'
import SchoolNewArticleDetail from '@/pages/school/new-article/[id]'
import SchoolArticles from '@/pages/school/[id]/articles'
import SchoolArticleDetail from '@/pages/school/[id]/articles/edit-article'
import SchoolNewCategory from '@/pages/school/new-category'
import Blogs from '@/pages/blog'
import BlogNew from '@/pages/blog/new'
import BlogDetail from '@/pages/blog/[id]'
import { Shop } from '@/pages/shop'
import ShopAddProduct from '@/pages/shop/add-product'
import { ShopDetail } from '@/pages/shop/[id]'
import ShopEditProduct from '@/pages/shop/[id]/edit-product'
import ChatPage from '@/pages/chat'
import FeedbackPage from "@/pages/feedback";
import Analytics from '@/pages/analytics'
import AnalyticDetailEdit from '@/pages/analytics/edit'
import AnalyticNew from '@/pages/analytics/new'
import ContentPage from '@/pages/content'
import Users from '@/pages/users'
import UserDetail from '@/pages/users/[id]'
import Marketing from '@/pages/marketing'
import General from '@/pages/general'
import Payments from '@/pages/payments'
import Experts from '@/pages/experts'
import ExpertDetail from '@/pages/experts/[id]'
import ExpertNew from '@/pages/experts/new'

export const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <SuspenseWrapper>
        <NotFound/>
      </SuspenseWrapper>
    )
  },
  {
    path: '/',
    element: (
      <SuspenseWrapper>
        <Dashboard/>
      </SuspenseWrapper>
    )
  },

  {
    path: 'statistics',
    children: [
      {
        index: true,
        element: (
          <SuspenseWrapper>
            <ContentPage/>
          </SuspenseWrapper>
        )
      },
      {
        path: 'general',
        element: (
          <SuspenseWrapper>
            <General/>
          </SuspenseWrapper>
        )
      },
      {
        path: 'payments',
        element: (
          <SuspenseWrapper>
            <Payments/>
          </SuspenseWrapper>
        )
      },
      {
        path: 'marketing',
        element: (
          <SuspenseWrapper>
            <Marketing/>
          </SuspenseWrapper>
        )
      },
      {
        path: 'users',
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <Users/>
              </SuspenseWrapper>
            )
          },
          {
            path: ':id',
            element: (
              <SuspenseWrapper>
                <UserDetail/>
              </SuspenseWrapper>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'content',
    children: [
      {
        index: true,
        element: (
          <SuspenseWrapper>
            <ContentPage/>
          </SuspenseWrapper>
        )
      },
      {
        path: 'school',
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <Schools/>
              </SuspenseWrapper>
            )
          },
          {
            path: ':id',
            children: [
              {
                path: 'edit-category',
                element: (
                  <SuspenseWrapper>
                    <SchoolEdit/>
                  </SuspenseWrapper>
                )
              },

              {
                path: 'article',
                element: (
                  <SuspenseWrapper>
                    <SchoolArticles/>
                  </SuspenseWrapper>
                )
              },
              {
                path: 'article/:id_article',
                element: (
                  <SuspenseWrapper>
                    <SchoolArticleDetail/>
                  </SuspenseWrapper>
                )
              }
            ]
          },
          {
            path: 'new-article/:id',
            element: (
              <SuspenseWrapper>
                <SchoolNewArticleDetail/>
              </SuspenseWrapper>
            )
          },
          {
            path: 'new-category',
            element: (
              <SuspenseWrapper>
                <SchoolNewCategory/>
              </SuspenseWrapper>
            )
          }
        ]
      },
      {
        path: 'blog',
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <Blogs/>
              </SuspenseWrapper>
            )
          },
          {
            path: ':id_blog',
            element: (
              <SuspenseWrapper>
                <BlogDetail/>
              </SuspenseWrapper>
            )
          },
          {
            path: 'new',
            element: (
              <SuspenseWrapper>
                <BlogNew/>
              </SuspenseWrapper>
            )
          }
        ]
      },
      {
        path: 'shop',
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <Shop/>
              </SuspenseWrapper>
            )
          },
          {
            path: 'add-product',
            element: (
              <SuspenseWrapper>
                <ShopAddProduct/>
              </SuspenseWrapper>
            )
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: (
                  <SuspenseWrapper>
                    <ShopDetail/>
                  </SuspenseWrapper>
                )
              },
              {
                path: 'edit-product',
                element: (
                  <SuspenseWrapper>
                    <ShopEditProduct/>
                  </SuspenseWrapper>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'analytics',
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <Analytics/>
              </SuspenseWrapper>
            )
          },
          {
            path: 'edit/:id',
            element: (
              <SuspenseWrapper>
                <AnalyticDetailEdit/>
              </SuspenseWrapper>
            )
          },
          {
            path: 'new',
            element: (
              <SuspenseWrapper>
                <AnalyticNew/>
              </SuspenseWrapper>
            )
          }
        ]
      },
      {
        path: 'experts',
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <Experts/>
              </SuspenseWrapper>
            )
          },
          {
            path: 'edit/:id',
            element: (
              <SuspenseWrapper>
                <ExpertDetail/>
              </SuspenseWrapper>
            )
          },
          {
            path: 'new',
            element: (
              <SuspenseWrapper>
                <ExpertNew/>
              </SuspenseWrapper>
            )
          },
          {
            path: ':id/analytics',
            element: (
              <SuspenseWrapper>
                <Analytics/>
              </SuspenseWrapper>
            )
          },
          {
            path: ':id/analytics/edit/:analytic_id',
            element: (
              <SuspenseWrapper>
                <AnalyticDetailEdit/>
              </SuspenseWrapper>
            )
          }
        ]
      },
      {
        path: 'chat',
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <ChatPage/>
              </SuspenseWrapper>
            )
          }
        ]
      },
      {
        path: 'feedback',
        children: [
          {
            index: true,
            element: (
              <SuspenseWrapper>
                <FeedbackPage/>
              </SuspenseWrapper>
            )
          }
        ]
      }
    ]
  }
])
