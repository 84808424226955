import { useMutation } from "@tanstack/react-query";
import FeedbackService from "@/shared/services/feedback.service";
import { IFeedbackAnswer } from "@/shared/services/types/feedback.types";
import { queryClient } from "@/shared/lib";

export const useAnswerToFeedback = () => {
  return useMutation(['feedback answer'], (data: IFeedbackAnswer) => FeedbackService.answerFeedback(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['get feedback list'])
    }
  })
}