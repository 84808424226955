import { IFeedback } from "@/shared/services/types/feedback.types";
import { FC } from "react";
import { format, parse } from "date-fns";
import styles from "./FeedbackMessage.module.scss";
import { clsx } from "clsx";
import { DeleteIconJSX } from "@/shared/assets/common/svg";

type IProps = {
  item: IFeedback
  isActive?: boolean
  onClick: (item: IFeedback) => void
  onDelete: (id: number) => void
}

export const Message: FC<IProps> = ({item, isActive, onClick, onDelete}) => {

  const parsedDate = new Date(item.createdAt)
  const createDate = format(parsedDate, 'yyyy-MM-dd HH:mm:ss')

  const onClickMessage = () => {
    onClick(item)
  }

  const onDeleteMessage = () => {
    onDelete(item.id)
  }

  return (
    <div className={clsx(styles.message, isActive && styles.messageActive)} onClick={onClickMessage}>
      <div className={styles.row}>
        <div className={styles.date}>{createDate}</div>
        <div className={styles.date}>{item.status}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.date}>{item.name}</div>
        <div className={styles.date}>{item.email}</div>
      </div>
      <div className={styles.row}>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{__html: item.message}}
        />
        <DeleteIconJSX className={styles.delete} onClick={onDeleteMessage}/>
      </div>
    </div>
  )
}