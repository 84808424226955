import axiosInstance from "@/shared/store/axiosGlobal";
import { getUserUrl } from "@/shared/api/config";
import { IFeedback, IFeedbackAnswer, IFeedbackList, IFeedbackSendMoney } from "@/shared/services/types/feedback.types";
import { toFormData } from "axios";


const FeedbackService = {
  async getList() {
    return axiosInstance.get<IFeedbackList>(
      getUserUrl(`/list_feedbacks?toDate=2025-03-12%2010%3A33%3A16.130180`)
    )
  },

  async getById(id: number) {
    return axiosInstance.get<IFeedback>(
      getUserUrl(`/get_feedback`), {
        params: {
          item_id: id
        }
      }
    )
  },

  async deleteFeedback(id: number) {
    return axiosInstance.delete(
      getUserUrl(`/delete_feedback`), {
        params: {
          item_id: id
        }
      }
    )
  },

  async answerFeedback(data: IFeedbackAnswer) {
    return axiosInstance.post<IFeedback>(
      getUserUrl(`/feedback_answer`), toFormData({feedback_id: data.feedback_id}), {
        params: {
          lang: data.lang,
          message: data.message
        }
      }
    )
  },

  async sendMoney(data: IFeedbackSendMoney) {
    return axiosInstance.post(
      getUserUrl('/user_add_money'), undefined, {
        params: {
          money: data.money,
          user_id: data.user_id,
          email: data.email
        }
      }
    )
  }
}

export default FeedbackService