import { useMutation } from "@tanstack/react-query";
import { IFeedbackSendMoney } from "@/shared/services/types/feedback.types";
import FeedbackService from "@/shared/services/feedback.service";
import { queryClient } from "@/shared/lib";

export const useSendMoney = () => {
  return useMutation(['feedback send money'], (data: IFeedbackSendMoney) => FeedbackService.sendMoney(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['get feedback list'])
    }
  })
}